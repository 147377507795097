import React from 'react'

import AppStoreIcon from '../assets/img/app-store-icon.svg?react'
import GooglePlayIcon from '../assets/img/google-play-icon.svg?react'

interface DownloadAppButtonProps {
  name: 'ios' | 'android'
}

const DownloadAppButton = ({ name }: DownloadAppButtonProps) => {
  const map: {
    [key in DownloadAppButtonProps['name']]: { icon: any; link: string }
  } = {
    ios: {
      icon: AppStoreIcon,
      link: 'https://apps.apple.com/us/app/guardely-sex-offender-locator/id6654921467',
    },
    android: {
      icon: GooglePlayIcon,
      link: 'https://play.google.com/store/apps/details?id=com.guardely.radarapp',
    },
  }

  const app = map[name]
  return (
    <a href={app.link} target="_blank">
      <app.icon />
    </a>
  )
}

export default DownloadAppButton
