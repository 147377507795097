import React, { useState } from 'react'
import FamilySidebar from './components/FamilySidebar'
import FamilyOffenders from './components/FamilyOffenders'
import AddFamilyMember from './components/AddFamilyMember'

import User1 from '../../assets/img/user-icon-1.png'
import User2 from '../../assets/img/user-icon-2.png'
import User3 from '../../assets/img/user-icon-3.png'
import User4 from '../../assets/img/user-icon-4.png'

export interface IUserFamily {
  name: string
  lastActivity: string
  image: string
  status: string
}

const users: IUserFamily[] = [
  {
    name: 'Robert Fox',
    lastActivity: '10 mins ago',
    image: User1,
    status: 'success',
  },
  {
    name: 'Jenny Fox',
    lastActivity: '5 mins ago',
    image: User2,
    status: 'success',
  },
  {
    name: 'Jacob Fox',
    lastActivity: '7 mins ago',
    image: User3,
    status: 'success',
  },
  {
    name: 'Darlene Fox',
    lastActivity: '',
    image: User4,
    status: 'pending',
  },
]

const index = () => {
  const [selectedUser, setSelectedUser] = useState<IUserFamily | null>(null)
  return (
    <>
      <main className="content">
        <div className="container h-full">
          <div className="catalog-page section-fullscreen headers-similars">
            <FamilySidebar
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
            <FamilyOffenders
              users={users.filter((user) => user.status !== 'pending')}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </div>
        </div>
      </main>
    </>
  )
}

export default index
