/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'
import UserAvatart1 from '../../../assets/img/mugshot-item-1.png'
import UserAvatart2 from '../../../assets/img/mugshot-item-2.png'
import NotificationPrimaryIcon from '../../../assets/img/ico-notification-1.svg?react'
import NotificationSecondaryIcon from '../../../assets/img/ico-notification-2.svg?react'
import Button from '../../../compoments/Button'
import Modal from '../../../compoments/Modal'
import { useGetMeQuery } from '../../../services/auth'
import { useAddUserMutation } from '../../../services/notifications'
import type { IOffender } from '../../../types'
import { useDispatch, useSelector } from 'react-redux'
import { getIsPremium, setIsPremium } from '../../../features/auth/authSlice'

interface OffenderPopupProps {
  data: IOffender
  isOpen: boolean
  onClose: () => void
}

const OffenderPopup = ({ data, isOpen, onClose }: OffenderPopupProps) => {
  const { data: userData, refetch } = useGetMeQuery()
  const [triggerAddUser] = useAddUserMutation()
  const isPremium = useSelector(getIsPremium)
  const dispatch = useDispatch()
  const [isNotify, setIsNotify] = useState(false)
  const selectedOffenders = userData?.data?.offenders || []

  useEffect(() => {
    if (selectedOffenders.includes(data?.id as string)) {
      setIsNotify(true)
    }
  }, [selectedOffenders])

  const handleChange = async () => {
    if (!data?.id) return

    try {
      setIsNotify(true)
      const res = await triggerAddUser({ offenderDocIds: [data.id] }).unwrap()

      if (res.success) {
        toast.success('User added successfully')
        await refetch()
      } else {
        setIsNotify(false)
        toast.error('User not added successfully')
      }
    } catch (e) {
      setIsNotify(false)
      toast.error('Please Login / Sign Up')
    }
  }

  const address = data?.address[0]

  if (!data) return null
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalClassNames={'modal-card'}
      modalSize={'modal__dialog--410'}
    >
      <div className="modal__header">
        <div className="user-avatar-group">
          <div className="user-avatar-72x72">
            <img
              src={`${import.meta.env.VITE_API_URL + '/api/radar-service/files/' + data.image_src}`}
              onError={(e) => {
                e.currentTarget.src = AgeRangeIcon
              }}
              alt="card-photo"
            />
          </div>
          <div className="user-info-col">
            <span className="user-info__name">{data.offenderName}</span>
            <span className="user-info__address">{address?.street}</span>
          </div>
        </div>
        <label className="btn">
          <input
            disabled={isNotify}
            type="checkbox"
            hidden
            checked={isNotify}
            onChange={handleChange}
          />
          <div className="btn btn--square btn--md rounded-full btn-check--likely">
            <span className="ico">
              <NotificationPrimaryIcon />
            </span>
            <span className="ico">
              <NotificationSecondaryIcon />
            </span>
          </div>
        </label>
      </div>
      <div className="modal__body">
        {isPremium && (
          <div className="form-group gap-10 mb-22">
            <label className="label">Mugshots</label>
            <div className="mugshots-group">
              <div className="mugshot-item">
                <img src={UserAvatart1} />
              </div>
              <div className="mugshot-item">
                <img src={UserAvatart1} />
              </div>
            </div>
          </div>
        )}
        <div className="form-group gap-10 mb-22">
          <label className="label">Age</label>
          <div className="info-block">{data.age || 'Not Specified'}</div>
        </div>
        {isPremium ? (
          <>
            <div className="form-group gap-10 mb-22">
              <label className="label">Addresses</label>
              <div className="info-block">
                {data.address?.length
                  ? data.address.map(({ street }) => street)
                  : 'Not Specified'}
              </div>
            </div>
            <div className="form-group gap-10 mb-22">
              <label className="label">Charges</label>
              <div className="info-block">
                Assault and Battery
                <br />
                Vandalism and Trespassing
              </div>
            </div>
            <div className="form-group gap-10">
              <label className="label">Aliases</label>
              <div className="info-block">
                {data.aliases || 'Not Specified'}
              </div>
            </div>
          </>
        ) : (
          <p className="modal-card__description">
            Upgrade to premium for detailed offender info!
            <br /> Unlock mugshots, charges, aliases, and addresses with our
            subscription.
          </p>
        )}
        {/* <div className="card card-custom-1 h-full">
          <div className="wrapper-image card-bg--image">
            <img
              src={`${import.meta.env.VITE_API_URL + '/api/radar-service/files/' + data.image_src}`}
              onError={(e) => {
                e.currentTarget.src = AgeRangeIcon
              }}
              alt="card-photo"
            />
            <div className="card-actions">
              <label className="btn">
                <input
                  disabled={isNotify}
                  type="checkbox"
                  hidden
                  checked={isNotify}
                  onChange={handleChange}
                />
                <div className="btn btn--square btn--md rounded-full btn-check--likely">
                  <span className="ico">
                    <NotificationPrimaryIcon />
                  </span>
                  <span className="ico">
                    <NotificationSecondaryIcon />
                  </span>
                </div>
              </label>
            </div>
          </div>

          <div className="card-body">
            <div className="heading-block">
              <span className="text--lg heading">{data.offenderName}</span>
              <p className="default-text text--sm heading-desk">
                {address?.street}
              </p>
              
              <div className="flex flex-col gap--md">
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Age</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.age || 'Not Specified'}
                  </p>
                </div>
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Addresses</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.address?.length
                      ? data.address.map(({ street }) => street)
                      : 'Not Specified'}
                  </p>
                </div>
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Charges</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    Assault and Battery <br />
                    Vandalism and Trespassing
                  </p>
                </div>
                <div className="card-details">
                  <label className="form-label">
                    <div className="label--text">
                      <span>Aliases</span>
                    </div>
                  </label>
                  <p className="card-details__body">
                    {data.aliases || 'Not Specified'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="modal__footer">
        {isPremium ? (
          <Button onClick={onClose}>
            <span>Arrests Information</span>
          </Button>
        ) : (
          <button
            type="button"
            className="btn btn--lg  btn--gradient rounded-full"
            onClick={() => dispatch(setIsPremium(true))}
          >
            <span className="ico">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
            <span>Upgrade to Premium</span>
          </button>
        )}
      </div>
    </Modal>
  )
}

export default OffenderPopup
