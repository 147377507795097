import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import SettingsIcon from '../../../assets/img/ico-settings.svg?react'
import TrashIcon from '../../../assets/img/ico-trash.svg?react'
import WarningIcon from '../../../assets/img/ico-warning.svg?react'
import { useGetMeQuery } from '../../../services/auth'
import {
  useAddUpdateZipAlertMutation,
  useDeleteZipAlertMutation,
} from '../../../services/notifications'
import type { IAlert } from '../../../types'

import Table from './Table'

interface ZipCodeAlertsTableProps {
  alerts: Pick<IAlert, 'zip' | 'range'>[] | []
  onEdit: (alert: any) => void
  onDelete: (alert: any) => void
}

const ZipCodeAlertsTable: React.FC<ZipCodeAlertsTableProps> = ({
  alerts,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation('alerts')
  const [triggerDeleteAllert] = useDeleteZipAlertMutation()
  const { refetch } = useGetMeQuery()

  const deleteZipAllert = async (zip: string) => {
    try {
      console.log(zip)

      const res = await triggerDeleteAllert({ zipcodes: [zip] }).unwrap()

      if (res.success) {
        toast.success('Zip code deleted successfully.')
        await refetch()
      }
    } catch (e) {
    } finally {
    }
  }

  return (
    <Table columns={[t('table_zip'), t('table_range'), '']}>
      {alerts.map((alert) => (
        <tr key={alert.zip}>
          <td>
            <div className="flex items-center gap--md">
              <div className="fake-btn btn btn--outline-danger rounded-full btn--square btn--md">
                <span className="ico">
                  <WarningIcon />
                </span>
              </div>
              <p className="text--md font-600">{alert.zip}</p>
            </div>
          </td>
          <td>{alert.range} miles</td>
          <td>
            <div className="flex-group gap--xs justify-end">
              <button
                className="btn btn--outline-default btn--md rounded-full"
                onClick={() => onEdit(alert)}
              >
                <span className="ico">
                  <SettingsIcon />
                </span>
                <p className="info">{t('table_edit')}</p>
              </button>
              <button
                className="btn btn--outline-danger btn--md rounded-full"
                onClick={async () => deleteZipAllert(alert.zip)}
              >
                <span className="ico">
                  <TrashIcon />
                </span>
                <p className="info">{t('table_delete')}</p>
              </button>
            </div>
          </td>
        </tr>
      ))}
    </Table>
  )
}

export default ZipCodeAlertsTable
