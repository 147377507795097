import React, { useState } from 'react'
import DownloadAppButtonGroup from '../../../compoments/DownloadAppButtonGroup'
import FilterIcon from '../../../assets/img/ico-filter.svg?react'
import User1 from '../../../assets/img/user-icon-1.png'
import User2 from '../../../assets/img/user-icon-2.png'
import User3 from '../../../assets/img/user-icon-3.png'
import User4 from '../../../assets/img/user-icon-4.png'
import EditIcon from '../../../assets/img/edit-user-icon.svg?react'
import ActiveIcon from '../../../assets/img/active-user-icon.svg?react'
import PluseIcon from '../../../assets/img/pluse-icon.svg?react'
import SaveZone1 from '../../../assets/img/icon-safe-zone-1.svg?react'
import SaveZone2 from '../../../assets/img/icon-safe-zone-2.svg?react'
import SaveZone3 from '../../../assets/img/icon-safe-zone-3.svg?react'
import { useTabs } from '../../../hooks/useTabs'
import clsx from 'clsx'
import AddFamilyMember from './AddFamilyMember'
import AddSaveZone from './AddSaveZone'
import { IUserFamily } from '..'
const tabs = [
  {
    id: 'family',
    label: 'Family',
  },
  {
    id: 'safe-zones',
    label: 'Safe Zones',
  },
] as const

const saveZones = [
  {
    name: 'Home',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: <SaveZone1 />,
  },
  {
    name: 'School',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: <SaveZone2 />,
  },
  {
    name: 'Work',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: <SaveZone3 />,
  },
]
interface IFamilySidebar {
  users: IUserFamily[]
  selectedUser: IUserFamily | null
  setSelectedUser: (value: IUserFamily) => void
}
const FamilySidebar = ({
  users,
  selectedUser,
  setSelectedUser,
}: IFamilySidebar) => {
  const [addFamilyModal, setAddFamilyModal] = useState<boolean>(false)
  const [addSaveZoneModal, setAddSaveZoneModal] = useState<boolean>(false)
  const { activeTab, setActiveTab } = useTabs(tabs)
  const handleSidebarToggle = () => {
    document.querySelector('body')?.classList.toggle('--toggle-side')
  }
  const familyModalHandler = () => {
    setAddFamilyModal((prev) => !prev)
  }
  const saveZoneModalHandler = () => {
    setAddSaveZoneModal((prev) => !prev)
  }
  return (
    <>
      <div className="catalog-page-aside--wrapper">
        <button
          onClick={handleSidebarToggle}
          className="btn btn--square btn--xl btn-aside side-toggler-js"
        >
          <span className="ico">
            <FilterIcon />
          </span>
        </button>
        <div className="catalog-page-aside scroll-styler">
          <div className="aside-header header-similar">
            <div className="tabs-nav-2">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={clsx('tab-link-3 btn btn--sm rounded-full', {
                    'active-tab': activeTab === tab.id,
                  })}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <span>{tab.label}</span>
                  <span>4</span>
                </button>
              ))}
            </div>
          </div>
          <div className="aside-body">
            {activeTab === 'family' && (
              <div className="tab mb-20 active-tab">
                <ul className="family-list mb-32">
                  {users.map((user, index) => (
                    <li className="family-list__item" key={index}>
                      <div
                        className={clsx(
                          'btn family-link w-full family-link-map-veiw',
                          { active: selectedUser?.name === user.name },
                        )}
                        onClick={() => {
                          if (selectedUser?.name === user.name) {
                            setSelectedUser(null!)
                          } else {
                            setSelectedUser(user)
                          }
                        }}
                      >
                        <div className="ico ico--sm family-link__icon rounded-full relative">
                          <img src={user.image} alt="" />
                          {selectedUser?.name === user.name && (
                            <span className="active-icon">
                              <ActiveIcon />
                            </span>
                          )}
                        </div>
                        <div className="family-link__text flex-auto">
                          <div className="family-link__title">{user.name}</div>
                          <div
                            className={clsx('family-link__text', {
                              '--status': user.status !== 'success',
                            })}
                          >
                            {user.status === 'success'
                              ? `Last activity: ${user.lastActivity}`
                              : 'Waiting for approval'}
                          </div>
                        </div>
                        <div className="family-link__actions">
                          <button
                            type="button"
                            onClick={() => {
                              if (user.status !== 'success') return
                              familyModalHandler()
                            }}
                            className="btn"
                          >
                            <span className="ico">
                              <EditIcon />
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  onClick={familyModalHandler}
                  className="btn btn--primary btn--lg rounded-full w-full"
                >
                  <span className="ico">
                    <PluseIcon />
                  </span>
                  <span>Add Member</span>
                </button>
              </div>
            )}
            {activeTab === 'safe-zones' && (
              <div className="tab mb-20 active-tab">
                <ul className="family-list mb-32">
                  {saveZones.map((zone, index) => (
                    <li className="family-list__item" key={index}>
                      <div className="btn family-link w-full family-link-map-veiw">
                        <div className="ico ico--sm family-link__icon rounded-full">
                          {zone.icon}
                        </div>
                        <div className="family-link__text flex-auto">
                          <div className="family-link__title">{zone.name}</div>
                          <div className={'family-link__text'}>
                            {zone.adress}
                          </div>
                        </div>
                        <div className="family-link__actions">
                          <button
                            onClick={saveZoneModalHandler}
                            className="btn"
                          >
                            <span className="ico">
                              <EditIcon />
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  onClick={saveZoneModalHandler}
                  className="btn btn--primary btn--lg rounded-full w-full"
                >
                  <span className="ico">
                    <PluseIcon />
                  </span>
                  <span>Add Safe Zone</span>
                </button>
              </div>
            )}
            <DownloadAppButtonGroup />
          </div>
        </div>
      </div>
      {addFamilyModal && (
        <AddFamilyMember isOpen={addFamilyModal} onClose={familyModalHandler} />
      )}
      {addSaveZoneModal && (
        <AddSaveZone isOpen={addSaveZoneModal} onClose={saveZoneModalHandler} />
      )}
    </>
  )
}

export default FamilySidebar
