import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import AgeRangeIcon from '../../../assets/img/ico-age-range.svg'

import SaveZone1 from '../../../assets/img/icon-safe-zone-1.svg'
import SaveZone2 from '../../../assets/img/icon-safe-zone-2.svg'
import SaveZone3 from '../../../assets/img/icon-safe-zone-3.svg'

import User1 from '../../../assets/img/user-icon-1.png'
import User2 from '../../../assets/img/user-icon-2.png'
import User3 from '../../../assets/img/user-icon-3.png'
import { IUserFamily } from '..'

const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

const saveZones = [
  {
    name: 'Home',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: SaveZone1,
  },
  {
    name: 'School',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: SaveZone2,
  },
  {
    name: 'Work',
    adress: '2464 Royal Ln. Mesa, New York',
    icon: SaveZone3,
  },
]
function getRandomCoordinate(base: number, range: number): number {
  return base + (Math.random() - 0.5) * range
}

interface IMap {
  users: IUserFamily[]
  selectedUser: IUserFamily | null
  setSelectedUser: (value: IUserFamily | null) => void
}

const Map = ({ users, selectedUser, setSelectedUser }: IMap) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const markersRef = useRef<mapboxgl.Marker[]>([])
  const pointMarkersRef = useRef<mapboxgl.Marker[]>([])
  const [myLocation, setMyLocation] = useState<[number, number]>([
    -78.16, 39.197372,
  ])
  const markersIsFisible = useRef(false)

  const [mapInstanse, setMapInstanse] = useState<mapboxgl.Map>()

  const createPersonMarkerElement = (
    // distanceTime: number,
    photo: string,
  ): HTMLDivElement => {
    const el = document.createElement('div')
    const imagePath = photo
    el.innerHTML = `
                        <div class="person-marker">
                        <div>
                            <img src="${imagePath}" onerror="this.onerror=null;this.src='${AgeRangeIcon}'" alt="" />
                        </div>
                    <!--        <span class="pill">10 min</span>-->
                        </div>
                    `
    return el
  }
  const toggleMarkersVisibility = () => {
    if (selectedUser) {
      renderPoints()
    } else {
      removePointsAndLine()
    }
  }

  const renderPoints = () => {
    // Координаты трех точек
    const points = [
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 12:00',
      },
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 13:00',
      },
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 14:00',
      },
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 15:00',
      },
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 16:00',
      },
      {
        coordinates: [
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ],
        text: '01.02.2024, 17:00',
      },
    ]
    // Добавляем маркеры для каждой точки
    const poinstMarkers = points.map((coord) => {
      const popup = new mapboxgl.Popup({ offset: 10 }).setText(coord.text)
      const el = document.createElement('div')
      el.innerHTML = `
                <div class="dot-marker"></div>
            `

      const marker = new mapboxgl.Marker(el)
        .setLngLat(coord.coordinates as [number, number])
        .setPopup(popup)
        .addTo(mapInstanse!)
      return marker
    })
    pointMarkersRef.current = poinstMarkers
    mapInstanse!.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: points.map((point) => point.coordinates),
        },
      },
    })

    mapInstanse!.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#F41658', // Цвет линии
        'line-width': 1, // Толщина линии
      },
    })
  }
  const removePointsAndLine = () => {
    // Удаляем все маркеры
    pointMarkersRef.current.forEach((marker) => marker.remove())
    pointMarkersRef.current = []

    // Удаляем линию, если она существует
    if (mapInstanse?.getLayer('route')) {
      mapInstanse.removeLayer('route')
    }
    if (mapInstanse?.getSource('route')) {
      mapInstanse.removeSource('route')
    }
  }
  useEffect(() => {
    if (mapInstanse && users) {
      markersRef.current.forEach((marker) => marker.remove())
      let filteredUsers = selectedUser ? [selectedUser] : users
      let markers = filteredUsers.map((markerData) => {
        const markerElement = createPersonMarkerElement(
          // markerData.distanceTime,
          markerData.image,
        )
        let marker = new mapboxgl.Marker(markerElement)
          .setLngLat([
            getRandomCoordinate(myLocation[0], 0.01),
            getRandomCoordinate(myLocation[1], 0.01),
          ])
          .addTo(mapInstanse)

        markerElement.addEventListener('click', () => {
          setSelectedUser(selectedUser ? null : markerData)
        })

        return marker
      })
      toggleMarkersVisibility()
      markersRef.current = markers
    }
  }, [users, mapInstanse])
  useEffect(() => {
    if (!mapContainerRef.current) return
    const map = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
      center: myLocation,
      zoom: 15.5,
    })

    const createHomeMarkerElement = (zone: string): HTMLDivElement => {
      const el = document.createElement('div')
      el.innerHTML = `
            <div class="home-marker">
              <div class="home-marker__inner">
                <div class="ico">
                   <img src="${zone}" onerror="this.onerror=null;this.src='${AgeRangeIcon}'" style='height: auto' alt="" />
                </div>
              </div>
            </div>
          `
      return el
    }
    saveZones.forEach((zone) => {
      new mapboxgl.Marker(createHomeMarkerElement(zone.icon))
        .setLngLat([
          getRandomCoordinate(myLocation[0], 0.01),
          getRandomCoordinate(myLocation[1], 0.01),
        ])
        .addTo(map)
    })
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    map.on('load', () => {
      map.resize()
    })
    setMapInstanse(map)
    return () => {
      map.remove()
      setMapInstanse(undefined)
    }
  }, [mapContainerRef])

  return (
    <div
      ref={mapContainerRef}
      className="map-container markers-blue-type"
      style={{ width: '100%', height: '100%' }}
    />
  )
}

export default Map
