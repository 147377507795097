import React, { ReactNode, useEffect, useRef, useState } from 'react'
import mapboxgl, { Marker } from 'mapbox-gl'
import Modal from '../../../compoments/Modal'
import clsx from 'clsx'
import MinusIcon from '../../../assets/img/ico-calc-minus.svg?react'
import PlusIcon from '../../../assets/img/ico-calc-plus.svg?react'
import Input from '../../../compoments/Input'

import PanIcon from '../../../assets/img/pen-icon.svg?react'
import SaveZone1 from '../../../assets/img/icon-safe-zone-1.svg?react'
import SaveZone2 from '../../../assets/img/icon-safe-zone-2.svg?react'
import SaveZone3 from '../../../assets/img/icon-safe-zone-3.svg?react'
import SaveZone4 from '../../../assets/img/icon-safe-zone-4.svg?react'
import SaveZone5 from '../../../assets/img/icon-safe-zone-5.svg?react'
import SaveZone6 from '../../../assets/img/icon-safe-zone-6.svg?react'
import SaveZone7 from '../../../assets/img/icon-safe-zone-7.svg?react'
import SaveZone8 from '../../../assets/img/icon-safe-zone-8.svg?react'
import SaveZone9 from '../../../assets/img/icon-safe-zone-9.svg?react'
import SaveZone10 from '../../../assets/img/icon-safe-zone-10.svg?react'
import SaveZone11 from '../../../assets/img/icon-safe-zone-11.svg?react'
import SaveZone12 from '../../../assets/img/icon-safe-zone-12.svg?react'
import Dropdown from '../../../compoments/Dropdown'

const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

interface AddSaveZoneProps {
  isOpen: boolean
  onClose: () => void
}
interface SaveZone {
  id: String
  icon: ReactNode
}

const saveZones: SaveZone[] = [
  {
    id: 'zone-1',
    icon: <SaveZone1 />,
  },
  {
    id: 'zone-2',
    icon: <SaveZone2 />,
  },
  {
    id: 'zone-3',
    icon: <SaveZone3 />,
  },
  {
    id: 'zone-4',
    icon: <SaveZone4 />,
  },
  {
    id: 'zone-5',
    icon: <SaveZone5 />,
  },
  {
    id: 'zone-6',
    icon: <SaveZone6 />,
  },
  {
    id: 'zone-7',
    icon: <SaveZone7 />,
  },
  {
    id: 'zone-8',
    icon: <SaveZone8 />,
  },
  {
    id: 'zone-9',
    icon: <SaveZone9 />,
  },
  {
    id: 'zone-10',
    icon: <SaveZone10 />,
  },
  {
    id: 'zone-11',
    icon: <SaveZone11 />,
  },
  {
    id: 'zone-12',
    icon: <SaveZone12 />,
  },
]
const DropdownMenu = ({
  confirmChange,
  selectedZone,
}: {
  confirmChange: (zone: SaveZone) => void
  selectedZone: SaveZone
}) => {
  const [currentChange, setCurrentChange] = useState<SaveZone>(selectedZone)
  return (
    <div className="safe-zone__icons-wrap">
      <div className="row g-8 mb-16">
        {saveZones.map((zone, index) => (
          <div className="col-3" key={index}>
            <label className="safe-zone__icon-item">
              <input
                type="radio"
                name="safe-zone"
                className="hidden"
                defaultChecked={currentChange.id === zone.id}
                onChange={() => {
                  setCurrentChange(zone)
                }}
              />
              <div className="safe-zone__icon">{zone.icon}</div>
            </label>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => confirmChange(currentChange)}
        className="btn btn--primary btn--xs radius-full w-full"
      >
        Confirm
      </button>
    </div>
  )
}

const AddSaveZone = ({ isOpen, onClose }: AddSaveZoneProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [range, setRange] = useState(0)
  const [selectedZone, setSelectedZone] = useState(saveZones[0])
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    -78.16, 39.197372,
  ])
  const [address, setAddress] = useState('')
  const [name, setName] = useState('Home')
  const markerRef = useRef<Marker | null>(null)
  const initMap = (mapContainer: HTMLDivElement | null) => {
    if (!mapContainer) return

    const map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
      center: markerPosition,
      zoom: 15.5,
    })

    const createHomeMarkerElement = (): HTMLDivElement => {
      const el = document.createElement('div')
      el.innerHTML = `<div class="home-marker marker-modal">
							<div class="home-marker__inner">
								<div class="ico">
					
								</div>
							</div>
						</div>`
      return el
    }

    markerRef.current = new mapboxgl.Marker({
      element: createHomeMarkerElement(),
      draggable: true,
    })
      .setLngLat(markerPosition)
      .addTo(map)

    markerRef.current.on('dragend', () => {
      const lngLat = markerRef.current?.getLngLat()
      if (lngLat) {
        setMarkerPosition([lngLat.lng, lngLat.lat])
      }
    })
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    map.on('load', () => {
      map.resize()
    })
    return map
  }
  useEffect(() => {
    const map = initMap(mapContainerRef.current)

    return () => map && map.remove()
  }, [isOpen])
  useEffect(() => {
    reverseGeocode()
  }, [markerPosition])

  const reverseGeocode = () => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${markerPosition[0]},${markerPosition[1]}.json?access_token=${mapBoxToken}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          setAddress(data.features[0].place_name)
        } else {
          console.log('Adress not found')
        }
      })
      .catch((error) => console.error('Ошибка:', error))
  }

  const forwardGeocode = (address: string) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address,
      )}.json?access_token=${mapBoxToken}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          const [lng, lat] = data.features[0].center
          setMarkerPosition([lng, lat])
          markerRef.current?.setLngLat([lng, lat])
          console.log(`Координаты: ${lng}, ${lat}`)
        } else {
          console.log('Координаты не найдены')
        }
      })
      .catch((error) => console.error('Ошибка:', error))
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className="modal__header-map"
        style={{
          height: '300px',
        }}
      >
        <div
          className="map"
          ref={mapContainerRef}
          style={{
            width: '100%',
            height: '100%',
          }}
        ></div>
      </div>
      <div className="modal__body">
        <h2 className="mb-32">Add Safe Zone</h2>
        <div className="row">
          <div className="col-md-12 col-12 mb-22">
            <div className="flex items-center gap-22">
              <Dropdown
                button={
                  <>
                    <div className="safe-zone__box-icon">
                      {selectedZone.icon}
                    </div>
                    <div className="safe-zone__edit-btn">
                      <PanIcon />
                    </div>
                  </>
                }
                wrapperClassNames={'safe-zone'}
                buttonClassName={'btn safe-zone__btn'}
                menu={
                  <DropdownMenu
                    confirmChange={(zone) => setSelectedZone(zone)}
                    selectedZone={selectedZone}
                  />
                }
              />
              <div className="form-group input--lg w-full">
                <Input
                  label={'Safe zone name'}
                  placeholder="Enter safe zone name..."
                  inputType="input--outline"
                  value={name}
                  onChange={({ target }) => {
                    setName(target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 mb-22">
            <div className="form-group input--lg">
              <Input
                label={'Address'}
                placeholder="Enter your address..."
                inputType="input--outline"
                value={address}
                onKeyUp={({ code, target }) => {
                  if (code === 'Enter') {
                    forwardGeocode((target as HTMLInputElement).value)
                  }
                }}
                onChange={({ target }) => {
                  setAddress(target.value)
                }}
              />
            </div>
          </div>
          <div className="col-md-12 col-12 mb-22">
            <div className="form-group input--icon-right input--lg">
              <label className="label">Set range</label>
              <div className="row-group gap--lg">
                <div
                  className={clsx('calculator', { '--null': range === 0 })}
                  data-calculator
                >
                  <button
                    onClick={() => setRange((prev) => prev + 1)}
                    type="button"
                    className="btn btn-calculator btn--square btn-calc--plus btn--outline-default rounded-full"
                  >
                    <span className="ico">
                      <PlusIcon />
                    </span>
                  </button>
                  <input
                    type="text"
                    value={range}
                    data-start="0"
                    data-calculator--input=""
                    className="input"
                    readOnly
                    style={{ width: 55 }}
                  />
                  <button
                    onClick={() => setRange((prev) => Math.max(0, prev - 1))}
                    type="button"
                    className="btn btn-calculator btn--square btn-calc--minus btn--outline-default rounded-full"
                  >
                    <span className="ico">
                      <MinusIcon />
                    </span>
                  </button>
                </div>
                <span className="color-text">Miles</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <button
          type="button"
          onClick={onClose}
          className="btn btn--secondary-light btn--lg rounded-full w-full"
        >
          Cancel
        </button>
        <a href="#" className="btn btn--primary btn--lg rounded-full w-full">
          Add Safe Zone
        </a>
      </div>
    </Modal>
  )
}

export default AddSaveZone
