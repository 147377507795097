/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import React from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  modalClassNames?: String
  modalSize?: String
}

const Modal = ({
  isOpen,
  onClose,
  children,
  modalClassNames = '',
  modalSize = 'modal__dialog--md',
}: ModalProps) => {
  return (
    <div
      id="modal"
      onClick={onClose}
      className={clsx('modal modal-1', modalClassNames, {
        'modal--show': isOpen,
      })}
    >
      <div className={`modal__dialog ${modalSize}`}>
        <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
