import clsx from 'clsx'
import { useEffect, useState } from 'react'
import type { PlaceholderProps, Props, SingleValue } from 'react-select'
import Select, { components } from 'react-select'

interface Option {
  label: string
  value: string | number
}

interface SelectProps extends Props<Option, false> {
  selectValue?: string | number | null | undefined
  options: Option[]
  wrapperClassName: string
  onChange?: (option: Option | null) => void
}

const Placeholder = (props: PlaceholderProps<Option>) => {
  return <components.Placeholder {...props} />
}

const SelectBox = ({
  options,
  selectValue,
  onChange,
  wrapperClassName = '',
  ...rest
}: SelectProps) => {
  const [value, setValue] = useState(findValue(selectValue))

  function findValue(value: string | number | undefined | null) {
    return options.find((option) => option.value === value) || null
  }
  function handleOnChange(option: SingleValue<Option>) {
    if (onChange) {
      onChange(option)
    }
    setValue(option)
  }

  useEffect(() => {
    setValue(findValue(selectValue))
  }, [selectValue])

  return (
    <div className={clsx('form-group', wrapperClassName)}>
      <Select
        options={options}
        value={value}
        theme={(theme) => ({ ...theme, borderRadius: 0 })}
        components={{
          IndicatorSeparator: () => null,
          Placeholder,
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        isSearchable={true}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        {...rest}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default SelectBox
