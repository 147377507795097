import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const notBase = 'api/notifications'

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers) => {
    headers.set(
      'Authorization',
      `Bearer ${window.localStorage.getItem('token')}`,
    )
    return headers
  },
})

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAllNotifications: builder.query<any, void>({
      query: () => `${notBase}`,
    }),
    readAllNot: builder.query<any, void>({
      query: () => `${notBase}/read-all`,
    }),
    countNot: builder.query<any, void>({
      query: () => `/api/notifications/count`,
    }),
    addUpdateZipAlert: builder.mutation<any, any>({
      query: (body) => ({
        url: `/api/users/zipcodealert`,
        method: 'POST',
        body,
      }),
    }),
    deleteZipAlert: builder.mutation<any, any>({
      query: (body) => ({
        url: `/api/users/zipcodealert`,
        method: 'DELETE',
        body,
      }),
    }),
    addUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/users/offender`,
        method: 'POST',
        body,
      }),
    }),
    deleteUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/users/offender`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
})

export const {
  useLazyCountNotQuery,
  useLazyReadAllNotQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useDeleteZipAlertMutation,
  useAddUpdateZipAlertMutation,
  useLazyGetAllNotificationsQuery,
  useGetAllNotificationsQuery,
} = notificationsApi
