import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom'

import AgeRangeIcon from '../assets/img/ico-age-range.svg?react'
import SettingsIcon from '../assets/img/ico-gear.svg?react'
import HomeIcon from '../assets/img/ico-home.svg?react'
import LogoutIcon from '../assets/img/ico-logout.svg?react'
import NotificationsSecondaryIcon from '../assets/img/ico-notification-2.svg?react'
import AlertsAdministrationIcon from '../assets/img/ico-squares-four.svg?react'
import CaretdownIcon from '../assets/img/icon-caretdown.svg?react'
import NotificationsIcon from '../assets/img/icon-notifications.svg?react'
import Logo from '../assets/img/logo.svg?react'
import { authApi, useGetMeQuery } from '../services/auth'
import {
  useGetAllNotificationsQuery,
  useLazyCountNotQuery,
} from '../services/notifications'
import { useLazyGetUsersQuery } from '../services/users'
import { searchOffendersByName } from '../slices/offendersSlice'
import type { IUser } from '../types'

import Dropdown from './Dropdown'
import SearchForm from './SearchForm'
import { getIsPremium, setIsPremium } from '../features/auth/authSlice'
import UpdatePremiumModal from './UpdatePremiumModal'

const DropdownButton = ({ avatar }: { avatar: string }) => (
  <>
    <div className="ico ico--sm primary-user-ico rounded-full overflow-hidden">
      {avatar ? (
        <img src={`${import.meta.env.VITE_API_URL}` + avatar} alt="user" />
      ) : (
        <AgeRangeIcon />
      )}
    </div>

    <span className="ico --size-22 justify-center btn-round-light drp-arrow-rotate">
      <span className="ico --size-12 justify-center">
        <CaretdownIcon />
      </span>
    </span>
  </>
)

const DropdownMenu = ({
  onLogout,
  data,
}: {
  onLogout: () => void
  data: IUser
}) => {
  const { t } = useTranslation('home')

  const routes = [
    {
      name: t('dropdown.home'),
      to: '/',
      icon: <HomeIcon />,
    },
    {
      name: t('dropdown.alerts'),
      to: '/alerts',
      icon: <AlertsAdministrationIcon />,
    },
    {
      name: t('dropdown.notifications'),
      to: '/notifications',
      icon: <NotificationsSecondaryIcon />,
    },
    {
      name: t('dropdown.settings'),
      to: '/settings',
      icon: <SettingsIcon />,
    },
  ]

  return (
    <>
      <div className="dropdown-content__header">
        <div className="dropdown-content__avatar">
          {data?.photo ? (
            <img
              src={`${import.meta.env.VITE_API_URL}` + data.photo}
              alt="user"
            />
          ) : (
            <AgeRangeIcon />
          )}
        </div>
        <div className="flex flex-col text-center">
          <span className="dropdown-content__user-name">{data?.fullName}</span>
          <span className="dropdown-content__user-email">{data?.email}</span>
        </div>
      </div>
      <div className="dropdown-content__settings">
        {routes.map((route, index) => (
          <NavLink
            to={route.to}
            className={({ isActive }) =>
              clsx('dropdown-content__setting-link', {
                'active-page': isActive,
              })
            }
            key={index}
          >
            <div className="setting-link-group-text">
              <span className="setting-link-icon">{route.icon}</span>
              {route.name}
            </div>
          </NavLink>
        ))}
        <button onClick={onLogout} className="dropdown-content__setting-link">
          <div className="setting-link-group-text">
            <span className="setting-link-icon">
              <LogoutIcon />
            </span>
            {t('dropdown.log_out')}
          </div>
        </button>
      </div>
    </>
  )
}
const DropdownMenuSubscribe = ({
  unSubscribe,
}: {
  unSubscribe: () => void
}) => {
  return (
    <div className="subscribe-drop">
      <div className="flex flex-col">
        <span className="dropdown-content__user-name">My Subscription:</span>
        <span className="dropdown-content__user-email">
          Renews on 9 Nov 2024
        </span>
        <span className="dropdown-content__user-email">$14.99 / month</span>
      </div>
      <button
        type="button"
        onClick={unSubscribe}
        className="btn btn--secondary-light btn--md rounded-full mt-20"
      >
        Cancel Subscription
      </button>
    </div>
  )
}

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [updatePremiumModal, setUpdatePremiumModal] = useState(false)
  const isPremium = useSelector(getIsPremium)
  const navigate = useNavigate()
  const { data } = useGetMeQuery()
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()
  const [notificationsCount, setNotificationsCount] = useState(0)

  const { data: notificationsData, refetch } = useGetAllNotificationsQuery()

  const [triggerGetNotCount] = useLazyCountNotQuery()

  const [firstInit, setFirstInit] = useState(false)

  const dispatch = useDispatch()

  const isAuthenticated = data?.data?.id

  const handleLogout = () => {
    window.localStorage.removeItem('token')
    dispatch(authApi.util.resetApiState())
    navigate('/login-email')
  }
  useEffect(() => {
    if (data?.success) {
      changeLanguage(data.data.language)
    }
  }, [data])

  useEffect(() => {
    const req = async () => {
      try {
        const res = await triggerGetNotCount().unwrap()

        if (res?.data?.count > notificationsCount) {
          refetch()
          setNotificationsCount(res?.data?.count)
        }
      } catch (e) {}
    }

    const interval = setInterval(() => {
      req()
    }, 60000)

    return () => clearInterval(interval)
  }, [notificationsData, triggerGetNotCount, refetch, notificationsCount])

  const [triggerGetUsers] = useLazyGetUsersQuery()

  const [nearByZip, setNearByZip] = useState<string | null>(null)

  const searchRequest = ({
    value,
    isName,
  }: {
    value: string
    isName: boolean
  }) => {
    if (isName) {
      triggerGetUsers({ name: value })
    } else {
      triggerGetUsers({ zip: value })
    }
  }

  useEffect(() => {
    if (!nearByZip) return
    if (firstInit) return
    // if (!isAuthenticated) return

    setFirstInit(true)

    // triggerGetUsers({ zip: nearByZip as string })
    //   .unwrap()
    //   .catch(() => {
    //     triggerGetUsers({ zip: '20190' })
    //   })
    // searchRequest({ value: nearByZip as string, isName: false })
  }, [isAuthenticated, firstInit, nearByZip])

  const getZipCode = (latitude: number, longitude: number) => {
    const accessToken = import.meta.env.VITE_MAP_BOX_KEY
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`

    fetch(url)
      .then(async (response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          const postalCodeFeature = data.features.find((feature: any) =>
            feature.place_type.includes('postcode'),
          )

          if (postalCodeFeature) {
            console.log(`ZIP code: ${postalCodeFeature.text}`)
            setNearByZip(postalCodeFeature.text as string)
          } else {
            console.error('Postal code not found.')
          }
        }
      })
      .catch((error) => console.error('Error with Mapbox API:', error))
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude

        getZipCode(latitude, longitude)
      },
      (error) => {
        // triggerGetUsers({ zip: '20190' })
        // searchRequest({ value: '20190', isName: false })
        // console.error('Geolocation error:', error)
      },
    )
  }, [])

  const handleSearch = async (value: string, isName: boolean) => {
    setSearchParams('')
    setIsSearching(true)
    navigate('/')
    if (isName) {
      try {
        await triggerGetUsers({
          // zip: (nearByZip as string) || '20190',
          name: value,
        }).unwrap()
      } catch (error) {
        // await triggerGetUsers({ zip: '20190' })
        console.log(error)
      } finally {
        setIsSearching(false)
      }
      // dispatch(searchOffendersByName(value))
    } else {
      try {
        await triggerGetUsers({
          zip: value || (nearByZip as string) || '20190',
        })
      } catch (error) {
        console.log(error)
        await triggerGetUsers({ zip: '20190' })
      } finally {
        setIsSearching(false)
      }
    }

    // searchRequest({ value, isName })
  }
  return (
    <>
      <header className="header header-4">
        <div className="container">
          <div className="header__body">
            <NavLink to={'/'} className="header__logo">
              <Logo className="header__logo_icon" />
            </NavLink>

            <div className={clsx('header__menu', { active: isMenuOpen })}>
              <span className="decor-delimiter"></span>
              <SearchForm
                isLoading={isSearching}
                onSearch={handleSearch}
                isPremium={isPremium}
                updateModalOpen={() => setUpdatePremiumModal(true)}
              />
              {isAuthenticated ? (
                <div className="hrader-notifications-group gap-16">
                  {isPremium ? (
                    <Dropdown
                      buttonClassName={'btn  btn--gradient-border rounded-full'}
                      button={
                        <span className=" btn--lg">
                          Subscribed to <b>Premium</b>
                        </span>
                      }
                      menuClassName={'dropdown__body__login radius-18'}
                      menu={
                        <DropdownMenuSubscribe
                          unSubscribe={() => dispatch(setIsPremium(false))}
                        />
                      }
                    />
                  ) : (
                    <button
                      onClick={() => setUpdatePremiumModal(true)}
                      className="btn btn--lg  btn--gradient rounded-full"
                    >
                      <span className="ico">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.8083 12.4319L20.0191 10.1989C20.1841 8.44621 20.2666 7.56896 19.9659 7.20687C19.8901 7.11277 19.7963 7.03461 19.6901 6.97687C19.584 6.91913 19.4674 6.88297 19.3472 6.87046C18.9108 6.83196 18.3636 7.45529 17.2682 8.70196C16.7017 9.34729 16.4184 9.66904 16.1031 9.71946C15.927 9.7471 15.7466 9.71822 15.5879 9.63696C15.2955 9.49029 15.1021 9.09154 14.7125 8.29496L12.6619 4.09296C11.9268 2.58687 11.5592 1.83337 11 1.83337C10.4408 1.83337 10.0733 2.58687 9.33809 4.09296L7.2875 8.29496C6.89884 9.09246 6.7045 9.49029 6.41209 9.63696C6.25321 9.71762 6.07304 9.74647 5.89692 9.71946C5.58159 9.66904 5.29834 9.34729 4.73184 8.70196C3.63642 7.45529 3.08917 6.83196 2.65284 6.87046C2.53261 6.88297 2.41605 6.91913 2.30986 6.97687C2.20367 7.03461 2.10995 7.11277 2.03409 7.20687C1.73434 7.56896 1.81684 8.44621 1.98184 10.1989L2.19175 12.4319C2.53825 16.1095 2.71059 17.9493 3.79592 19.0575C4.8785 20.1667 6.50284 20.1667 9.75334 20.1667H12.2458C15.4953 20.1667 17.1197 20.1667 18.2041 19.0575C19.2885 17.9484 19.4618 16.1095 19.8083 12.4319Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span>Upgrade</span>
                    </button>
                  )}
                  <Link to={'/notifications'} className="hrader-notifications">
                    <NotificationsIcon />
                    {notificationsData?.data?.length ? (
                      <span className="hrader-notifications__cunter">
                        {notificationsData?.data?.length}
                      </span>
                    ) : null}
                  </Link>
                  <span className="decor-delimiter"></span>
                  <Dropdown
                    buttonClassName={'btn user-dropdown-btn px-10px'}
                    button={<DropdownButton avatar={data?.data?.photo || ''} />}
                    menuClassName={'dropdown__body__login radius-18'}
                    menu={
                      <DropdownMenu onLogout={handleLogout} data={data?.data} />
                    }
                  />
                </div>
              ) : (
                <Link
                  to={'/create-account'}
                  className="btn btn--lg btn--primary rounded-full"
                >
                  Sign Up / Login
                </Link>
              )}
            </div>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={clsx('header__burger', { active: isMenuOpen })}
            >
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <UpdatePremiumModal
        isOpen={updatePremiumModal}
        onClose={() => setUpdatePremiumModal(false)}
      />
    </>
  )
}

export default Header
