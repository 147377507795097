import clsx from 'clsx'
import React, { memo } from 'react'

const sizes = {
  lg: 'h-16 w-16',
  md: 'h-8 w-8',
  sm: 'h-4 w-4',
  xl: 'h-24 w-24',
}

const variants = {
  light: 'text-white',
  primary: 'text-blue-200',
}

export type SpinnerProps = {
  className?: string
  size?: keyof typeof sizes
  variant?: keyof typeof variants
}

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = memo(
  ({ className = '', size = 'md', variant = 'primary' }: SpinnerProps) => {
    return (
      <svg
        className="animate-spin"
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_43_8197)">
          <path
            d="M14 28.5C6.28017 28.5 0 22.2198 0 14.5C0 6.78017 6.28017 0.5 14 0.5C21.7198 0.5 28 6.78017 28 14.5C28 22.2198 21.7198 28.5 14 28.5ZM14 2.83333C7.567 2.83333 2.33333 8.067 2.33333 14.5C2.33333 20.933 7.567 26.1667 14 26.1667C20.433 26.1667 25.6667 20.933 25.6667 14.5C25.6667 8.067 20.433 2.83333 14 2.83333Z"
            fill="#0A3313"
          />
          <path
            d="M14.0001 23.8333C8.85391 23.8333 4.66675 19.6461 4.66675 14.5C4.66675 9.35379 8.85391 5.16663 14.0001 5.16663C16.4932 5.16663 18.8371 6.13729 20.5999 7.90013C20.7082 8.00844 20.7942 8.13704 20.8528 8.27856C20.9114 8.42009 20.9416 8.57177 20.9416 8.72496C20.9416 8.87815 20.9114 9.02983 20.8528 9.17136C20.7942 9.31288 20.7082 9.44147 20.5999 9.54979C20.4916 9.65811 20.363 9.74403 20.2215 9.80266C20.08 9.86128 19.9283 9.89145 19.7751 9.89145C19.6219 9.89145 19.4702 9.86128 19.3287 9.80266C19.1872 9.74403 19.0586 9.65811 18.9502 9.54979C18.3016 8.89789 17.5301 8.38107 16.6804 8.02922C15.8307 7.67737 14.9197 7.49748 14.0001 7.49996C10.1396 7.49996 7.00008 10.6395 7.00008 14.5C7.00008 18.3605 10.1396 21.5 14.0001 21.5C14.9197 21.5024 15.8307 21.3225 16.6804 20.9707C17.5301 20.6189 18.3016 20.102 18.9502 19.4501C19.0586 19.3418 19.1872 19.2559 19.3287 19.1973C19.4702 19.1386 19.6219 19.1085 19.7751 19.1085C19.9283 19.1085 20.08 19.1386 20.2215 19.1973C20.363 19.2559 20.4916 19.3418 20.5999 19.4501C20.7082 19.5584 20.7942 19.687 20.8528 19.8286C20.9114 19.9701 20.9416 20.1218 20.9416 20.275C20.9416 20.4281 20.9114 20.5798 20.8528 20.7214C20.7942 20.8629 20.7082 20.9915 20.5999 21.0998C19.7352 21.9692 18.7067 22.6584 17.5738 23.1276C16.4409 23.5968 15.2263 23.8367 14.0001 23.8333Z"
            fill="#0A3313"
          />
          <path
            d="M14 19.1666C11.4264 19.1666 9.33337 17.0736 9.33337 14.4999C9.33337 11.9263 11.4264 9.83325 14 9.83325C15.2437 9.83325 16.4162 10.3198 17.2982 11.2018C17.4065 11.3101 17.4924 11.4387 17.5511 11.5802C17.6097 11.7217 17.6399 11.8734 17.6399 12.0266C17.6399 12.1798 17.6097 12.3315 17.5511 12.473C17.4924 12.6145 17.4065 12.7431 17.2982 12.8514C17.1899 12.9597 17.0613 13.0457 16.9198 13.1043C16.7782 13.1629 16.6266 13.1931 16.4734 13.1931C16.3202 13.1931 16.1685 13.1629 16.027 13.1043C15.8855 13.0457 15.7569 12.9597 15.6485 12.8514C15.4327 12.6339 15.1759 12.4614 14.8929 12.3439C14.6099 12.2263 14.3065 12.1661 14 12.1666C12.7132 12.1666 11.6667 13.2131 11.6667 14.4999C11.6667 15.7868 12.7132 16.8333 14 16.8333C14.6207 16.8333 15.2064 16.5906 15.6485 16.1484C15.7569 16.0401 15.8855 15.9542 16.027 15.8956C16.1685 15.8369 16.3202 15.8068 16.4734 15.8068C16.6266 15.8068 16.7782 15.8369 16.9198 15.8956C17.0613 15.9542 17.1899 16.0401 17.2982 16.1484C17.4065 16.2567 17.4924 16.3853 17.5511 16.5269C17.6097 16.6684 17.6399 16.8201 17.6399 16.9733C17.6399 17.1264 17.6097 17.2781 17.5511 17.4196C17.4924 17.5612 17.4065 17.6898 17.2982 17.7981C16.8662 18.2328 16.3522 18.5776 15.7861 18.8125C15.22 19.0474 14.6129 19.1677 14 19.1666Z"
            fill="#0A3313"
          />
          <path
            d="M13.1752 15.3248C13.0668 15.2165 12.9807 15.0879 12.922 14.9464C12.8633 14.8049 12.8331 14.6531 12.8331 14.4999C12.8331 14.3467 12.8633 14.195 12.922 14.0534C12.9807 13.9119 13.0668 13.7833 13.1752 13.6751L22.0769 4.77342C22.2956 4.55466 22.5923 4.43176 22.9017 4.43176C23.2111 4.43176 23.5078 4.55466 23.7265 4.77342C23.9453 4.99218 24.0682 5.28888 24.0682 5.59825C24.0682 5.90763 23.9453 6.20433 23.7265 6.42309L14.8249 15.3248C14.7166 15.4332 14.588 15.5192 14.4465 15.5779C14.305 15.6366 14.1533 15.6668 14 15.6668C13.8468 15.6668 13.6951 15.6366 13.5536 15.5779C13.412 15.5192 13.2835 15.4332 13.1752 15.3248ZM22.1667 16.8333C20.8799 16.8333 19.8334 15.7868 19.8334 14.4999C19.8334 13.2131 20.8799 12.1666 22.1667 12.1666C23.4535 12.1666 24.5 13.2131 24.5 14.4999C24.5 15.7868 23.4535 16.8333 22.1667 16.8333Z"
            fill="#0A3313"
          />
        </g>
        <defs>
          <clipPath id="clip0_43_8197">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  },
  () => true,
)
Spinner.displayName = 'Spinner'

export default Spinner
