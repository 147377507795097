import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { IUser } from '../types'

import type {
  ChangePasswordRequestType,
  IResponseType,
  LoginPhoneRequestType,
  LoginPhoneResponseType,
  LoginRequestType,
  LoginResponseType,
  RecoveryResponseType,
  RegisterResponseType,
  TestRecoveryRequestType,
} from './types'

const authBase = 'api/auth'

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers) => {
    headers.set(
      'Authorization',
      `Bearer ${window.localStorage.getItem('token')}`,
    )
    return headers
  },
})

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (body) => ({
        url: `${authBase}/signin`,
        method: 'POST',
        body,
      }),
    }),

    loginGoogle: builder.query<any, void>({
      query: () => `/oauth/google`,
    }),

    // TODO типизировать запрос ответ
    register: builder.mutation<any, any>({
      query: (body) => ({
        url: `${authBase}/signup`,
        method: 'POST',
        body,
      }),
    }),

    getMe: builder.query<any, void>({
      query: () => `api/users/me`,
    }),

    updateMe: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/users/me`,
        method: 'PUT',
        body,
      }),
    }),

    forgotPassword: builder.mutation<any, any>({
      query: (body) => ({
        url: `${authBase}/forgot-password`,
        method: 'POST',
        body,
      }),
    }),

    loginByPhone: builder.mutation<any, any>({
      query: (body) => ({
        url: `${authBase}/signin-by-phone`,
        method: 'POST',
        body,
      }),
    }),
    requestRecovery: builder.mutation<RecoveryResponseType, string>({
      query: (email) => ({
        url: `${authBase}/forgot-password`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    testRecoveryCode: builder.mutation<
      RecoveryResponseType,
      TestRecoveryRequestType
    >({
      query: (body) => ({
        url: `${authBase}/test-forgot-code`,
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation<
      RegisterResponseType,
      ChangePasswordRequestType
    >({
      query: (body) => ({
        url: `${authBase}/restore-password`,
        method: 'POST',
        body,
      }),
    }),
    loginPhone: builder.mutation<LoginPhoneResponseType, LoginPhoneRequestType>(
      {
        query: (body) => ({
          url: `${authBase}/signin-by-phone`,
          method: 'POST',
          body,
        }),
      },
    ),
  }),
})

export const {
  useLoginByPhoneMutation,
  useLazyLoginGoogleQuery,
  useForgotPasswordMutation,
  useLoginMutation,
  useGetMeQuery,
  useRegisterMutation,
  useUpdateMeMutation,
  useRequestRecoveryMutation,
  useTestRecoveryCodeMutation,
  useChangePasswordMutation,
  useLoginPhoneMutation,
} = authApi
