import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import MarkAsReadIcon from '../../assets/img/icon-mark-as-read.svg?react'
import Button from '../../compoments/Button'
import { useGetMeQuery } from '../../services/auth'
import {
  useLazyGetAllNotificationsQuery,
  useLazyReadAllNotQuery,
} from '../../services/notifications'

import Notification from './components/Notification'

const NotificationsPage = () => {
  const { t } = useTranslation('notifications')
  const { data: userData } = useGetMeQuery()
  const [triggerNotifications, { data: notificationsData }] =
    useLazyGetAllNotificationsQuery()

  const [triggerReadAll] = useLazyReadAllNotQuery()

  useEffect(() => {
    if (userData?.data?.id) {
      triggerNotifications()
    }
  }, [userData?.data])

  const [notifications, setNotifications] = useState([])
  // const notifications = [
  //   // {
  //   //   title: 'Notification Title',
  //   //   content: 'Here will be a description of the notification...',
  //   //   isRead: false,
  //   // },
  //   // {
  //   //   title: 'Notification Title',
  //   //   content: 'Here will be a description of the notification...',
  //   //   isRead: true,
  //   // },
  //   // {
  //   //   title: 'Notification Title',
  //   //   content: 'Here will be a description of the notification...',
  //   //   isRead: true,
  //   // },
  //   // {
  //   //   title: 'Notification Title',
  //   //   content: 'Here will be a description of the notification...',
  //   //   isRead: true,
  //   // },
  // ]

  useEffect(() => {
    if (notificationsData?.data) {
      setNotifications(
        notificationsData?.data.map((n: any) => ({
          title:
            n.type === 'zip'
              ? `Notification zip ${n.offenderDocumentId}`
              : 'Notification ',
          content: 'Here will be a description of the notification...',
          isRead: n.notificationReadStatus,
        })),
      )
    }
  }, [notificationsData])

  const handleReadAll = async () => {
    const res = await triggerReadAll().unwrap()

    if (res.success) {
      toast.success(res.message)
    }
  }

  return (
    <main className="content">
      <div className="container h-full">
        <div className="card card-border h-full notifications-page">
          <div className="card-header border-none">
            <div className="card-header--item gap-12">
              <h1 className="heading font-500">{t('title')}</h1>
              <span className="notifications-cunter">
                {notificationsData?.data?.length}
              </span>
            </div>
            <div className="card-header--item">
              <Button
                onClick={handleReadAll}
                className="btn btn--primary btn--lg rounded-full"
              >
                <span className="ico">
                  <MarkAsReadIcon />
                </span>
                {t('read')}
              </Button>
            </div>
          </div>
          <div className="card-body overflow-hidden">
            <div className="table-wrapper scroll-styler">
              <table className="table" cellPadding="0" cellSpacing="0">
                <tbody>
                  {notifications?.length
                    ? notifications.map((notification, index) => (
                        <tr
                          key={index}
                          className={clsx({
                            'new-notification': !notification?.isRead || false,
                          })}
                        >
                          <td>
                            <Notification
                              content={notification?.content || ''}
                              title={notification?.title || ''}
                            />
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotificationsPage
