import { createSlice } from '@reduxjs/toolkit'

import { authApi } from '../../services/auth'

interface AuthState {
  isPremium: boolean
}
const initialState = {
  isPremium: false,
} satisfies AuthState as AuthState

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setIsPremium: (state, action) => {
      state.isPremium = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getMe.matchRejected,
      (state, { payload }) => {
        window.localStorage.removeItem('authToken')
      },
    )
  },
})

export const { setIsPremium } = alertsSlice.actions
export const getIsPremium = (state: any) => state.auth.isPremium
export default alertsSlice.reducer
