import React from 'react'
import Modal from '../../../compoments/Modal'
import SelectBox from '../../../compoments/SelectBox'
import { getCountries, getPhoneCode } from 'libphonenumber-js'
import Input from '../../../compoments/Input'
import { ChangePhoto } from '../../Settings/Parts/ChangePhoto'
interface AddFamilyMemberProps {
  isOpen: boolean
  onClose: () => void
}
const AddFamilyMember = ({ isOpen, onClose }: AddFamilyMemberProps) => {
  const countries = getCountries()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__header">
        <div className="modal__header-block">
          <h2 className="modal__header-title">Add Family Member</h2>
          <p className="modal__header-description">
            Fill in for to create new family member.
          </p>
        </div>
      </div>
      <div className="modal__body">
        <div className="row">
          <div className="col-12 mb-44">
            <ChangePhoto />
          </div>
          <div className="col-md-12 col-12 mb-22">
            <Input
              label={'Full name'}
              placeholder="Enter your full name..."
              inputType="input--outline"
            />
          </div>
          <div className="col-md-12 col-12 mb-22">
            <div className="form-group input--lg">
              <Input
                label={'Email'}
                placeholder="Enter your email..."
                inputType="input--outline"
              />
            </div>
          </div>
          <div className="col-md-12 col-12 mb-28">
            <div className="row">
              <div className="col-4">
                <div className="form-group select--outline input--lg">
                  <label className="label">Country</label>
                  <SelectBox
                    wrapperClassName={`select--outline input--sm standard-height`}
                    options={countries.map((item) => ({
                      label: `${item} +${getPhoneCode(item)}`,
                      value: item,
                    }))}
                  />
                </div>
              </div>
              <div className="col-8">
                <div className="form-group input--lg">
                  <Input
                    label={'Phone number'}
                    placeholder="Enter your phone number..."
                    inputType="input--outline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <button
          type="button"
          onClick={onClose}
          className="btn btn--secondary-light btn--lg rounded-full w-full"
        >
          Cancel
        </button>
        <a href="#" className="btn btn--primary btn--lg rounded-full w-full">
          Send Invitation
        </a>
      </div>
    </Modal>
  )
}

export default AddFamilyMember
